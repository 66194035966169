import { ConnectedRouter } from 'connected-react-router';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
// @ts-ignore
import { AnalyticsProvider } from 'use-analytics';

import { analytics } from 'common/analytics/analytics';
import AntDesignProvider from 'common/components/AntDesignProvider/AntDesignProvider';
import AuthenticationProvider from 'common/components/AuthenticationProvider/AuthenticationProvider';
import DefaultIntlProvider from 'common/components/DefaultIntlProvider/DefaultIntlProvider';
import ScrollToTop from 'common/components/ScrollToTop';
import store, { history } from 'redux/store';
import Routes from 'routes';
import { theme } from 'theme/theme';

const App = () => (
  <AnalyticsProvider instance={analytics}>
    <Provider store={store}>
      {/* @ts-ignore */}
      <ConnectedRouter history={history}>
        <CookiesProvider>
          {/*
        // @ts-ignore */}
          <DefaultIntlProvider>
            <AntDesignProvider>
              <ThemeProvider theme={theme}>
                <AuthenticationProvider>
                  <ScrollToTop />
                  <Routes />
                </AuthenticationProvider>
              </ThemeProvider>
            </AntDesignProvider>
          </DefaultIntlProvider>
        </CookiesProvider>
      </ConnectedRouter>
    </Provider>
  </AnalyticsProvider>
);

export default App;
