import { Component } from 'react';
import { instanceOf, PropTypes } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toggleFetching } from '../../reducers/ui';

import { fetchUserAndCompanies } from '../../reducers/user';

import LoadingPage from '../LoadingPage';
import analytics from 'common/analytics/analytics';

export class AuthenticationProvider extends Component {
  componentDidMount() {
    this.handleAuth();
  }

  handleAuth = async () => {
    const { cookies } = this.props;
    const token = cookies.get('token');
    if (!token) {
      const { toggleFetchingAction } = this.props;
      return toggleFetchingAction(false);
    }
    return this.authUser(token);
  };

  authUser = async (token) => {
    const { fetchUserAndCompaniesAction } = this.props;
    await fetchUserAndCompaniesAction(token);
    const { user } = this.props;
    analytics.identify(user.uuid);
  };

  render() {
    const { isFetching, children } = this.props;
    return isFetching ? <LoadingPage /> : children;
  }
}

AuthenticationProvider.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
  isFetching: PropTypes.bool.isRequired,
  toggleFetchingAction: PropTypes.func.isRequired,
  fetchUserAndCompaniesAction: PropTypes.func.isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    profile: PropTypes.shape({
      phoneNumber: PropTypes.string.isRequired,
    }),
    currentCompanyId: PropTypes.number,
  }).isRequired,
};

const connectedAuthenticationProvider = connect(
  (state) => ({
    token: state.user.token,
    isFetching: state.ui.isFetching,
    user: state.user,
  }),
  {
    toggleFetchingAction: toggleFetching,
    fetchUserAndCompaniesAction: fetchUserAndCompanies,
  }
)(AuthenticationProvider);

export default withRouter(withCookies(connectedAuthenticationProvider));
